import { Box, Text } from '@chakra-ui/react';
import React from 'react';

function Copyright() {
  return (
    <Box
      pos="absolute"
      bottom={{ base: 2, sm: 4, md: 6 }}
      left={{ base: 2, sm: 4, md: 6 }}
      zIndex="sticky"
    >
      <Text
        fontWeight="semibold"
        fontSize="xs"
        fontFamily="'Gilroy', sans-serif"
        color="whiteAlpha.400"
      >
        Image by Micah Denn, under CC BY-SA 4.0
      </Text>
    </Box>
  );
}

export default Copyright;
