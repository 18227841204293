import { Grid } from '@chakra-ui/react';
import { DisplayItem } from 'components/molecules';
import type { Display } from 'core/types/display';

const ROW_GAP_REM = 6;
const COLUMN_GAP_REM = 10;

type DisplayContainerProps = {
  displays: Display[];
};

function DisplayContainer({ displays }: DisplayContainerProps) {
  return (
    <Grid
      templateColumns={{ base: 'none', sm: 'repeat(auto-fit, minmax(30%, 1fr))' }}
      rowGap={ROW_GAP_REM}
      columnGap={COLUMN_GAP_REM}
    >
      {displays.map((display) => (
        <DisplayItem key={display.id} display={display} />
      ))}
    </Grid>
  );
}

export default DisplayContainer;
