import { DEFAULTS } from 'core/config/displayDefaults';
import { Display } from 'core/types/display';
import { resetDisplayQuery } from 'gql';
import deleteGroupQuery from 'gql/deleteGroupQuery';
import {
  addGroupQuery,
  applyRecommendedSettingsQuery,
  resetPlaylistQuery,
  updateSourceQuery,
} from 'gql/resetDisplayQuery';
import {
  getDisplayDefaultAlias,
  getDisplayDefaultOrientation,
  getDisplayDefaultSource,
} from 'utils/display';
import logger from 'utils/logger';
import { client } from '../client';

async function handleGroupDeletion(display: Display, groupId: string) {
  const removableGroupIds = display.groups.filter((gr) => gr.id !== groupId).map((gr) => gr.id);
  if (removableGroupIds.length > 0) {
    await client.request(deleteGroupQuery, {
      id: display.id,
      groupIds: removableGroupIds,
    });
  }
}

async function handleGroupDefault(display: Display, groupId: string) {
  const defaultGroup = display.groups.find((gr) => gr.id === groupId);
  if (!defaultGroup) await client.request(addGroupQuery, { id: display.id, groupId });
}

const reset = async ({
  display,
  powerScheduleId,
  playlistId,
  groupId,
}: {
  display: Display;
  powerScheduleId: string;
  playlistId: string;
  groupId: string;
}) => {
  try {
    const variables = {
      id: display.id,
      powerScheduleId,
      playlistId,
      groupId,
      alias: getDisplayDefaultAlias(display),
      orientation: getDisplayDefaultOrientation(display),
      source: getDisplayDefaultSource(display),
    };
    await client.request(resetDisplayQuery, variables);
    if (playlistId !== 'null') await client.request(resetPlaylistQuery, variables); // Merge into previous query when TPVWAVE-630 is resolved
    if (DEFAULTS.recommended) await client.request(applyRecommendedSettingsQuery, variables);
    if (variables.source !== 'null') await client.request(updateSourceQuery, variables);
    await handleGroupDeletion(display, groupId);
    await handleGroupDefault(display, groupId);
  } catch (e) {
    logger.error(JSON.stringify(e, undefined, 2));
    throw new Error('Resetting display failed');
  }
};

export default reset;
