import type { ButtonProps } from '@chakra-ui/button';
import { Button } from '@chakra-ui/react';

const CancelButton = ({ ...rest }: ButtonProps) => {
  const { value } = rest;
  return (
    <Button
      {...rest}
      bgColor="transparent"
      borderRadius={4}
      px={6}
      py={3}
      fontSize="md"
      fontWeight="semibold"
      color={rest.color ?? 'red.400'}
      title={String(value)}
      _hover={{ bgColor: 'red.50' }}
      _active={{ bgColor: 'transparent' }}
      _disabled={{ bgColor: 'red.50', opacity: 0.5 }}
    >
      {value}
    </Button>
  );
};

export default CancelButton;
