import { gql } from 'graphql-request';

// Create group
export const deleteGroupQuery = gql`
  mutation DeleteGroup($id: ID!, $groupIds: [ID!]!) {
    displayBulkRemoveGroups(input: { displayIds: [$id], groupIds: $groupIds }) {
      displays {
        id
      }
    }
  }
`;

export default deleteGroupQuery;
