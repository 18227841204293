import { Flex } from '@chakra-ui/react';
import { Copyright } from 'components/atoms';
import { Outlet } from 'react-router-dom';

function HomeLayout() {
  return (
    <>
      <Flex
        as="main"
        flexDirection="column"
        width="100vw"
        height="100vh"
        justifyContent="center"
        alignItems="center"
        px={{ base: 4, sm: 2, md: 0 }}
        bgImage="/assets/background.png"
        bgPosition="center"
        bgSize="cover"
      >
        <Outlet />
      </Flex>
      <Copyright />
    </>
  );
}

export default HomeLayout;
