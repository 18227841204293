import { Box, Text, VStack } from '@chakra-ui/react';
import { MainButton } from 'components/atoms';
import { useNavigate } from 'react-router-dom';

function Error() {
  const navigate = useNavigate();

  const onGoBack = () => {
    navigate(-1);
  };

  return (
    <Box
      bgColor="white"
      boxShadow="login"
      borderRadius="base"
      px={{ base: 4, md: 10 }}
      py={{ base: 8, md: 10 }}
      w="full"
      maxW="lg"
    >
      <VStack spacing={6}>
        <Text fontWeight="semibold">Page not found!</Text>
        <MainButton value="Go back" onClick={onGoBack} isSecondary />
      </VStack>
    </Box>
  );
}

export default Error;
