import { ButtonProps, useToast } from '@chakra-ui/react';
import { MainButton } from 'components/atoms';
import { useReset } from 'core/contexts/ResetProvider';
import { useData } from 'core/hooks/useData';
import { useMemo } from 'react';
import { checkDisplayDefaults } from 'utils/display';
import { errorToast, successToast } from 'utils/toast';

function ResetAllButton({ ...rest }: ButtonProps) {
  const toast = useToast();
  const { data } = useData();
  const { resetAllDisplays, busyDisplays, allBusy } = useReset();

  const isLoading =
    busyDisplays.length > 0 ||
    allBusy ||
    data?.customer.displays.some((d) => d.playlist?.sync != null);

  const allAlreadyDefault = useMemo(() => {
    return data?.customer.displays.every((d) => checkDisplayDefaults(d));
  }, [data?.customer.displays]);

  const onClick = async () => {
    await resetAllDisplays()
      .then(() => {
        toast(successToast('All displays reset successfully'));
      })
      .catch((e) => {
        toast(errorToast(e.message));
      });
  };

  return (
    <MainButton
      value={allAlreadyDefault ? 'All displays set to default' : 'Reset all displays'}
      onClick={onClick}
      isLoading={isLoading}
      isDisabled={isLoading || allAlreadyDefault}
      minW={56}
      {...rest}
    />
  );
}

export default ResetAllButton;
