import { Flex, Text } from '@chakra-ui/react';
import React, { memo } from 'react';

type DisplaySpecificationProps = {
  label: string;
  value: string;
};

function DisplaySpecification({ label, value }: DisplaySpecificationProps) {
  return (
    <Flex flexDir="row" wrap="wrap">
      <Text w={16}>{label}</Text>
      <Text fontWeight="semibold">{value}</Text>
    </Flex>
  );
}

export default memo(DisplaySpecification);
