import { reset } from 'core/api';
import { client } from 'core/api/client';
import { useData } from 'core/hooks/useData';
import { DisplaysData } from 'core/types/data';
import { BusyDisplay, Display } from 'core/types/display';
import getDisplaysQuery from 'gql/getDisplaysQuery';
import { createContext, useContext, useState } from 'react';
import { wait } from 'utils/app';
import {
  checkCustomerGroups,
  checkDisplayDefaults,
  checkDisplayGroups,
  getDisplayDefaultGroup,
  getDisplayDefaultPlaylist,
  getDisplayDefaultProperties,
} from 'utils/display';
import logger from 'utils/logger';
import { useCustomer } from './CustomerProvider';

interface ResetContext {
  allBusy: boolean;
  busyDisplays: Display[];
  setBusyDisplays: (displays: Display[]) => void;
  resetDisplay: (display: Display) => Promise<void>;
  resetAllDisplays: () => Promise<void>;
}

const initialState: ResetContext = {
  allBusy: false,
  busyDisplays: [],
  setBusyDisplays: () => undefined,
  resetDisplay: () => Promise.resolve(),
  resetAllDisplays: () => Promise.resolve(),
};

interface ResetProviderProps {
  children: React.ReactNode;
}

const ResetContext = createContext<ResetContext>(initialState);

export const useReset = () => useContext(ResetContext);

const DISPLAY_CHECK_INTERVAL = 2 * 1000;

const ResetProvider = ({ children }: ResetProviderProps) => {
  const { customer } = useCustomer();
  const { data, refresh } = useData();
  const [allBusy, setAllBusy] = useState(false);
  const [busyDisplays, setBusyDisplays] = useState<Display[]>([]);

  async function resetDisplay(display: Display) {
    try {
      if (!data || !data?.customer.displays) return;
      logger.info('Resetting display...');
      setBusyDisplays((prev) => [...prev, display]);
      const groupToCheck = getDisplayDefaultGroup(display);
      await checkDisplayGroups(data, groupToCheck);
      let busyDisplay: BusyDisplay | null;
      busyDisplay = { ...display, busy: getDisplayDefaultPlaylist(display) !== 'null' };
      const displayProperties = getDisplayDefaultProperties(data, display);
      await reset(displayProperties);
      logger.info('Sent the request!');
      do {
        logger.info('Checking display...');
        const newData = await client.request<DisplaysData>(getDisplaysQuery, { id: customer?.id });
        const newDisplay = newData.customer.displays.find((d) => d.id === display.id);
        const incomplete = newDisplay?.playlist?.sync;
        if (incomplete) busyDisplay = { ...newDisplay, busy: false };
        if (!incomplete && !busyDisplay?.busy) busyDisplay = null;
        await wait(DISPLAY_CHECK_INTERVAL);
      } while (busyDisplay);
      logger.info('Done!');
      await refresh();
    } catch (error) {
      logger.error(error);
      throw error || new Error('Failed to reset display');
    } finally {
      setBusyDisplays((prev) => prev.filter((d) => d.id !== display.id));
    }
  }

  async function resetAllDisplays() {
    try {
      if (!data || !data?.customer.displays) return;
      logger.info('Resetting all displays...');
      setAllBusy(true);
      await checkCustomerGroups(data);
      let busyArr: BusyDisplay[] = [];
      busyArr = data.customer.displays
        .filter((display) => {
          return !checkDisplayDefaults(display);
        })
        .map((display) => {
          return { ...display, busy: getDisplayDefaultPlaylist(display) !== 'null' };
        });
      const displayPromises = busyArr.map(async (display) => {
        const displayProperties = getDisplayDefaultProperties(data, display);
        await reset(displayProperties);
      });
      await Promise.all(displayPromises);
      logger.info('Sent all requests!');
      do {
        logger.info('Checking displays...');
        const newData = await client.request<DisplaysData>(getDisplaysQuery, { id: customer?.id });
        const incomplete = newData.customer.displays.filter((k) => k.playlist?.sync !== null);
        const complete = newData.customer.displays.filter((k) => k.playlist?.sync === null);

        for (const display of incomplete) {
          busyArr = busyArr.map((k) => {
            if (k.id === display.id) {
              return { ...k, busy: false };
            }
            return { ...k };
          });
        }

        for (const display of complete) {
          const find = busyArr.find((i) => i.id === display.id);
          if (find && !find.busy) {
            const index = busyArr.findIndex((o) => o.id === find.id);
            if (index !== -1) busyArr.splice(index, 1);
          }
        }
        await wait(DISPLAY_CHECK_INTERVAL);
      } while (busyArr.length > 0);
      logger.info('Done!');
      await refresh();
    } catch (error) {
      logger.error(error);
      throw error || new Error('Failed to reset all displays');
    } finally {
      setAllBusy(false);
    }
  }

  const value: ResetContext = {
    allBusy,
    busyDisplays,
    setBusyDisplays,
    resetDisplay,
    resetAllDisplays,
  };

  return <ResetContext.Provider value={value}>{children}</ResetContext.Provider>;
};

export default ResetProvider;
