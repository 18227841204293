import type { ButtonProps } from '@chakra-ui/button';
import { Button } from '@chakra-ui/react';
import { LoadingIcon } from '..';

interface MainButtonProps extends ButtonProps {
  isSecondary?: boolean;
}

const MainButton = ({ isSecondary, ...rest }: MainButtonProps) => {
  const { value } = rest;
  return (
    <Button
      {...rest}
      bgColor={isSecondary ? 'transparent' : 'blue.700'}
      color={isSecondary ? 'blue.500' : 'white'}
      border={isSecondary ? '1px solid' : 'none'}
      borderColor={isSecondary ? 'blue.200' : 'none'}
      borderRadius={4}
      fontSize="md"
      fontWeight="semibold"
      whiteSpace={'pre-wrap'}
      title={String(value)}
      spinner={<LoadingIcon />}
      _hover={isSecondary ? { bgColor: 'blue.100' } : { bgColor: 'blue.600' }}
      _active={isSecondary ? { bgColor: 'blue.200' } : { bgColor: 'blue.800' }}
      _disabled={{ bgColor: 'gray.200', opacity: isSecondary ? 0.5 : 1 }}
    >
      {value}
    </Button>
  );
};

export default MainButton;
