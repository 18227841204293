import { LoginForm } from 'components/organisms';

const Login = () => {
  return (
    <>
      <LoginForm />
    </>
  );
};

export default Login;
