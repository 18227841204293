import { useAuth } from 'core/contexts/AuthProvider';
import { Navigate } from 'react-router-dom';

type ProtectedRouteProps = {
  children: JSX.Element;
};

function NormalRoute({ children }: ProtectedRouteProps): JSX.Element {
  const { user } = useAuth();
  if (user) return <Navigate to="/displays" replace />;

  return children;
}

export default NormalRoute;
