import type { User } from 'core/types/user';
import { getUserQuery } from 'gql';
import logger from 'utils/logger';
import { client } from '../client';

const login = async (apiKey: string) => {
  try {
    client.setHeader('authorization', `Basic ${apiKey}`);
    await client.request(getUserQuery);
    const user: User = {
      apiKey,
    };
    return user;
  } catch (e) {
    client.setHeader('authorization', '');
    logger.error(JSON.stringify(e, undefined, 2));
    throw new Error('Login failed');
  }
};

export default login;
