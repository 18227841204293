import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Stack,
} from '@chakra-ui/react';
import { MainButton } from 'components/atoms';
import { useAuth } from 'core/contexts/AuthProvider';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

const API_KEY = 'apiKey';

interface Inputs {
  apiKey: string;
}

function LoginForm() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setFocus,
    setError,
  } = useForm<Inputs>();
  const { authenticate } = useAuth();

  const onSubmit: SubmitHandler<Inputs> = async ({ apiKey }) => {
    try {
      await authenticate(apiKey);
    } catch (e) {
      setError(API_KEY, {
        type: 'custom',
        message: e as string,
      });
    }
  };

  useEffect(() => {
    setFocus(API_KEY);
  }, [setFocus]);

  return (
    <Box
      bgColor="white"
      boxShadow="login"
      borderRadius="base"
      px={{ base: 4, md: 10 }}
      py={{ base: 8, md: 10 }}
      w="full"
      maxW="lg"
    >
      <Stack dir="column" spacing={14} w="full">
        <Stack direction="column" spacing={{ base: 4, md: 6 }}>
          <Flex position="relative" justifyContent="center">
            <Image
              src="/assets/loginLogo.svg"
              alt="Wave PPDS"
              objectFit="contain"
              w="full"
              h={40}
            />
          </Flex>
          <Heading color="gray.900" fontSize="2xl" textAlign="center">
            ISE Display Controller
          </Heading>
        </Stack>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" spacing={7}>
            <FormControl isInvalid={Boolean(errors.apiKey)}>
              <FormLabel htmlFor={API_KEY} color="blue.800" fontSize="md" fontWeight="semibold">
                API key
              </FormLabel>
              <Input
                id={API_KEY}
                type="text"
                placeholder="Fill in your API key here"
                {...register(API_KEY, {
                  required: 'API key is required',
                })}
              />
              <FormErrorMessage>{errors.apiKey && errors.apiKey.message}</FormErrorMessage>
            </FormControl>
            <MainButton value="Log in" type="submit" isLoading={isSubmitting} />
          </Stack>
        </form>
      </Stack>
    </Box>
  );
}

export default LoginForm;
