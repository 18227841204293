import { gql } from 'graphql-request';

// Create group
export const createGroupQuery = gql`
  mutation CreateGroup($customerId: ID!, $name: String!) {
    groupCreate(input: { customerId: $customerId, name: $name }) {
      group {
        id
        name
      }
    }
  }
`;

export default createGroupQuery;
