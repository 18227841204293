import {
  AuthLayout,
  HomeLayout,
  NormalRoute,
  ProtectedLayout,
  ProtectedRoute,
} from 'components/templates';
import { Customers, Displays, Error, Login } from 'pages';
import { createBrowserRouter } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: [
      {
        element: <HomeLayout />,
        children: [
          {
            path: '/',
            element: (
              <NormalRoute>
                <Login />
              </NormalRoute>
            ),
            index: true,
          },
          {
            path: '/customers',
            element: (
              <ProtectedRoute>
                <Customers />
              </ProtectedRoute>
            ),
          },
          {
            path: '*',
            element: <Error />,
          },
        ],
      },
      {
        element: <ProtectedLayout />,
        children: [
          {
            path: '/displays',
            element: (
              <ProtectedRoute>
                <Displays />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
]);
