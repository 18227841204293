import { UseToastOptions } from '@chakra-ui/react';

export function successToast(title: string, description?: string): UseToastOptions {
  return {
    position: 'top',
    title,
    description,
    status: 'success',
    duration: 3500,
    isClosable: true,
  };
}

export function errorToast(title: string, description?: string): UseToastOptions {
  return {
    position: 'top',
    title,
    description,
    status: 'error',
    duration: 3500,
    isClosable: true,
  };
}
