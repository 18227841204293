import { Box } from '@chakra-ui/react';
import { AuthProvider, CustomerProvider } from 'core/contexts';
import { Outlet } from 'react-router-dom';

const AuthLayout = () => {
  return (
    <AuthProvider>
      <CustomerProvider>
        <Box minH="full" minW="full">
          <Outlet />
        </Box>
      </CustomerProvider>
    </AuthProvider>
  );
};

export default AuthLayout;
