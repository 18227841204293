import { DisplayDefaults, DisplayOrientation, DisplayPower } from 'core/types/display';

export const DEFAULTS: DisplayDefaults = {
  power: process.env.REACT_APP_DEFAULT_POWER as DisplayPower,
  powerScheduleTitle: process.env.REACT_APP_DEFAULT_POWER_SCHEDULE as string,
  playlistTitle: process.env.REACT_APP_DEFAULT_PLAYLIST as string,
  source: process.env.REACT_APP_DEFAULT_SOURCE as string,
  orientation: process.env.REACT_APP_DEFAULT_ORIENTATION as DisplayOrientation,
  volume: {
    mute: process.env.REACT_APP_DEFAULT_VOLUME_MUTE === 'true',
    level: Number(process.env.REACT_APP_DEFAULT_VOLUME_LEVEL) || 0,
  },
  groupTitle: process.env.REACT_APP_DEFAULT_GROUP as string,
  brightness: Number(process.env.REACT_APP_DEFAULT_BRIGHTNESS) || 100,
  recommended: process.env.REACT_APP_DEFAULT_RECOMMENDED === 'true',
};
