import { Stack, Text } from '@chakra-ui/react';
import { MainButton } from 'components/atoms';
import { memo } from 'react';

type ErrorContainerProps = {
  refresh: () => void;
};

const ErrorContainer = memo(({ refresh }: ErrorContainerProps) => {
  return (
    <Stack w="full" h="full" dir="column" spacing={5} justifyContent="center" alignItems="center">
      <Text>
        An error occurred while loading your displays. Please try refreshing or logging in again.
      </Text>
      <MainButton value="Refresh" onClick={refresh} isSecondary />
    </Stack>
  );
});

export default ErrorContainer;
