import { gql } from 'graphql-request';

// Authentication
const getUserQuery = gql`
  {
    me {
      id
    }
  }
`;

export default getUserQuery;
