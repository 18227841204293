import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Heading, Link, Spacer, Stack, useToast } from '@chakra-ui/react';
import { DisplaySpecification, MainButton } from 'components/atoms';
import { useReset } from 'core/contexts/ResetProvider';
import { useData } from 'core/hooks/useData';
import { Display } from 'core/types/display';
import { useMemo } from 'react';
import { checkDisplayDefaults } from 'utils/display';
import { errorToast, successToast } from 'utils/toast';

const WAVE_BASEURL = 'https://wave.ppds.com';

interface DisplayItemProps {
  display: Display;
}

function DisplayItem({ display }: DisplayItemProps) {
  const toast = useToast();
  const { data } = useData();
  const { resetDisplay, allBusy, busyDisplays } = useReset();
  const { alias, commercialTypeNumber, serialNumber } = display;
  const isLoading =
    allBusy || busyDisplays.some((d) => d.id === display.id) || display.playlist?.sync != null;

  const displayUrl = useMemo(() => {
    return `${WAVE_BASEURL}/${data?.organization.handle}/customers/${data?.customer.handle}/displays/${display.id}`;
  }, [data?.organization.handle, data?.customer.handle, display.id]);

  const alreadyDefault = useMemo(() => {
    return checkDisplayDefaults(display);
  }, [display]);

  const onClick = async () => {
    await resetDisplay(display)
      .then(() => {
        toast(successToast('Display reset successfully'));
      })
      .catch((e) => {
        toast(errorToast(e.message));
      });
  };

  return (
    <Stack dir="column" p={4} borderRadius={4} bg="white" shadow="displayItem" w="full">
      <Stack dir="column" w="full" align="start" spacing={3}>
        <Link href={displayUrl} maxW="full" isExternal>
          <Heading
            as="h3"
            fontSize="2xl"
            fontWeight="semibold"
            display="flex"
            justifyContent="center"
            gap={1}
            maxW="full"
            whiteSpace="pre-wrap"
            wordBreak="break-word"
          >
            {alias ?? 'No given alias'} <ExternalLinkIcon w={4} alignSelf="center" />
          </Heading>
        </Link>
        <Stack align="start" spacing={2} fontSize="md" fontWeight="normal" color="gray.600">
          <DisplaySpecification label="Model" value={commercialTypeNumber} />
          <DisplaySpecification label="S/N" value={serialNumber} />
        </Stack>
      </Stack>
      <Spacer />
      <MainButton
        value={alreadyDefault ? 'All settings set to default' : 'Reset display'}
        w="full"
        mt={6}
        onClick={onClick}
        isLoading={isLoading}
        isDisabled={isLoading || alreadyDefault}
        isSecondary
      />
    </Stack>
  );
}

export default DisplayItem;
