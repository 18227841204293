import { createGroup } from 'core/api';
import { DEFAULTS } from 'core/config/displayDefaults';
import { CustomerData } from 'core/types/data';
import { Display, DisplayOrientation, DisplayPower } from 'core/types/display';
import logger from './logger';

export function getDisplayDefaultProperties(data: CustomerData, display: Display) {
  const groupName = getDisplayDefaultGroup(display);
  const powerSchedule = data?.customer.powerSchedules.find(
    (ps) => ps.title === DEFAULTS.powerScheduleTitle,
  );
  const defaultPlaylist = getDisplayDefaultPlaylist(display);
  let playlist;
  if (defaultPlaylist === 'null') {
    playlist = {
      id: 'null',
      title: 'null',
    };
  } else {
    playlist = data?.customer.playlists.find((pl) => pl.title === defaultPlaylist);
  }
  const group = data?.customer.groups.find((gr) => gr.name === groupName);
  if (!powerSchedule) throw new Error(`Power schedule '${DEFAULTS.powerScheduleTitle}' not found`);
  if (!playlist) throw new Error(`Playlist '${defaultPlaylist}' not found`);
  if (!group) throw new Error(`Group '${groupName}' not found`);
  return {
    display,
    powerScheduleId: powerSchedule.id,
    playlistId: playlist.id,
    groupId: group.id,
  };
}

export async function checkCustomerGroups(data: CustomerData) {
  const groupsSet = new Set<string>();
  data.customer.displays.forEach(async (display) => {
    const group = getDisplayDefaultGroup(display);
    groupsSet.add(group);
  });

  const groupPromises = Array.from(groupsSet).map(async (group) => {
    await checkDisplayGroups(data, group);
  });

  await Promise.all(groupPromises);
}

export async function checkDisplayGroups(data: CustomerData, groupName: string) {
  if (!data) return;
  if (!data.customer.groups.find((gr) => gr.name === groupName)) {
    logger.info(`Creating new group '${groupName}' ...`);
    const groupData = {
      customerId: data.customer.id,
      name: groupName,
    };
    const group = await createGroup(groupData);
    data.customer.groups.push(group);
  }
}

export function checkDisplayDefaults(display: Display) {
  if (!display.powerSchedule?.schedule) return false;
  if (!display.playlist.current && getDisplayDefaultPlaylist(display) !== 'null') return false;
  if (display.groups.length === 0) return false;

  return (
    checkAlias(display.serialNumber, display.alias) &&
    checkPower(display.power.reported) &&
    checkPowerScheduleTitle(display.powerSchedule.schedule.title) &&
    checkPlaylistTitle(display.serialNumber, display.playlist.current?.title) &&
    checkSource(display.serialNumber, display.inputSource.current.reported.source) &&
    checkOrientation(display.serialNumber, display.orientation?.reported) &&
    checkVolumeMute(display.volume.isMuted.reported) &&
    checkBrightness(display.brightness?.reported) &&
    checkRecommended(display.recommendedSettings.reported.recommended) &&
    display.groups.find((gr) => checkGroup(gr.name, display)) != null
  );
}

function checkAlias(serialNumber: string, alias: string | null) {
  if (!alias) return true;
  const defaultAlias = process.env[`REACT_APP_DEFAULT_ALIAS_${serialNumber}`] ?? serialNumber;
  return alias === defaultAlias;
}

function checkPower(power: DisplayPower) {
  return power === DEFAULTS.power;
}

function checkPowerScheduleTitle(title: string) {
  return title === DEFAULTS.powerScheduleTitle;
}

function checkPlaylistTitle(serialNumber: string, title?: string) {
  if (!title) return true;
  const defaultPlaylist =
    process.env[`REACT_APP_DEFAULT_PLAYLIST_${serialNumber}`] ?? DEFAULTS.playlistTitle;
  return defaultPlaylist === 'null' ? true : title === defaultPlaylist;
}

function checkSource(serialNumber: string, source: string) {
  const defaultSource = process.env[`REACT_APP_DEFAULT_SOURCE_${serialNumber}`] ?? DEFAULTS.source;
  return defaultSource === 'null' ? true : source === defaultSource;
}

function checkOrientation(serialNumber: string, orientation: DisplayOrientation | undefined) {
  if (!orientation) return true;
  const defaultOrientation =
    process.env[`REACT_APP_DEFAULT_ORIENTATION_${serialNumber}`] ?? DEFAULTS.orientation;
  return orientation === defaultOrientation;
}

function checkVolumeMute(mute: boolean) {
  return mute === DEFAULTS.volume.mute;
}

function checkBrightness(brightness: number | undefined) {
  if (brightness == null) return true;
  return brightness === DEFAULTS.brightness;
}

function checkRecommended(recommended: boolean) {
  return DEFAULTS.recommended ? recommended : true;
}

function checkGroup(groupName: string, display: Display) {
  return groupName === getDisplayDefaultGroup(display);
}

export function getDisplayDefaultOrientation(display: Display) {
  return (
    process.env[`REACT_APP_DEFAULT_ORIENTATION_${display.serialNumber}`] ?? DEFAULTS.orientation
  );
}

export function getDisplayDefaultPlaylist(display: Display) {
  return (
    process.env[`REACT_APP_DEFAULT_PLAYLIST_${display.serialNumber}`] ?? DEFAULTS.playlistTitle
  );
}

export function getDisplayDefaultSource(display: Display) {
  return process.env[`REACT_APP_DEFAULT_SOURCE_${display.serialNumber}`] ?? DEFAULTS.source;
}

export function getDisplayDefaultAlias(display: Display) {
  return process.env[`REACT_APP_DEFAULT_ALIAS_${display.serialNumber}`] ?? display.serialNumber;
}

export function getDisplayDefaultGroup(display: Display) {
  return process.env[`REACT_APP_DEFAULT_GROUP_${display.serialNumber}`] ?? DEFAULTS.groupTitle;
}
