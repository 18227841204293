import { gql } from 'graphql-request';

// Authentication
const getAllCustomersQuery = gql`
  {
    organization {
      customers {
        id
        name
      }
    }
  }
`;

export default getAllCustomersQuery;
