import { createGroupQuery } from 'gql';
import logger from 'utils/logger';
import { client } from '../client';

type CreateGroup = {
  groupCreate: {
    group: {
      id: string;
      name: string;
    };
  };
};

const createGroup = async ({ customerId, name }: { customerId: string; name: string }) => {
  try {
    const variables = {
      customerId,
      name,
    };
    const { groupCreate } = await client.request<CreateGroup>(createGroupQuery, variables);
    return groupCreate.group;
  } catch (e) {
    logger.error(JSON.stringify(e, undefined, 2));
    throw new Error('Creating group has failed.');
  }
};

export default createGroup;
