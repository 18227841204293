import { useAuth } from 'core/contexts/AuthProvider';
import { useCustomer } from 'core/contexts/CustomerProvider';
import { Navigate, useLocation } from 'react-router-dom';

type ProtectedRouteProps = {
  children: JSX.Element;
};

function ProtectedRoute({ children }: ProtectedRouteProps): JSX.Element {
  const { user } = useAuth();
  const { customer } = useCustomer();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/" />;
  }

  if (!customer && location.pathname !== '/customers') {
    return <Navigate to="/customers" />;
  }

  return children;
}

export default ProtectedRoute;
