import { gql } from 'graphql-request';

export const getCustomerQuery = gql`
  query GetCustomer($id: ID!) {
    customer(id: $id) {
      id
      name
      handle
      displays {
        id
        alias
        commercialTypeNumber
        serialNumber
        power {
          reported
        }
        powerSchedule {
          schedule {
            title
          }
        }
        inputSource {
          current {
            reported {
              source
            }
          }
        }
        orientation {
          reported
        }
        volume {
          isMuted {
            reported
          }
          level {
            reported
          }
        }
        brightness {
          reported
        }
        recommendedSettings {
          reported {
            recommended
          }
        }
        groups {
          id
          name
        }
        playlist {
          current {
            title
          }
          sync {
            id
            ... on PlaylistSyncing {
              progress
            }
          }
        }
      }
      powerSchedules {
        id
        title
      }
      playlists {
        id
        title
      }
      groups {
        id
        name
      }
    }
    organization {
      handle
    }
  }
`;

export default getCustomerQuery;
