import { client } from 'core/api/client';
import { useAuth } from 'core/contexts/AuthProvider';
import { useCustomer } from 'core/contexts/CustomerProvider';
import { CustomerData, CustomerDataError } from 'core/types/data';
import { getCustomerQuery } from 'gql';
import { useEffect } from 'react';
import useSWR from 'swr';

export function useData() {
  const { deauthenticate } = useAuth();
  const { customer } = useCustomer();

  const swrVariables = {
    id: customer?.id,
  };

  const { data, error, isLoading, mutate } = useSWR<CustomerData, CustomerDataError>(
    customer ? [getCustomerQuery, swrVariables] : null,
    ([query, variables]) => client.request(query, variables),
  );

  useEffect(() => {
    if (error?.response?.errors?.find((e) => e.message === 'UNAUTHORIZED')) {
      deauthenticate();
    }
  }, [error, deauthenticate]);

  return {
    data,
    error,
    loading: isLoading,
    refresh: mutate,
  };
}
