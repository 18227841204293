import type { CookieAttributes } from 'js-cookie';
import { isDev } from 'utils/app';

const COOKIE_NAME = 'ise_reset_user';
const COOKIE_AGE = 1;
const COOKIE_SECURE = isDev ? false : true;

const COOKIE_OPTIONS: CookieAttributes = {
  expires: COOKIE_AGE,
  secure: COOKIE_SECURE,
};

export const userCookie = {
  name: COOKIE_NAME,
  options: COOKIE_OPTIONS,
};
