import { InfoIcon } from '@chakra-ui/icons';
import { Button, Heading, HStack, Spacer, Tooltip } from '@chakra-ui/react';
import { TooltipLabel } from 'components/atoms';
import { ResetAllButton } from 'components/molecules';
import { DisplayContainer, ErrorContainer, SkeletonContainer } from 'components/organisms';
import { useData } from 'core/hooks/useData';
import { useNavigate } from 'react-router-dom';

const Displays = () => {
  const { data, error, loading, refresh } = useData();
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/customers');
  };

  return (
    <>
      {error ? (
        <ErrorContainer refresh={refresh} />
      ) : loading || !data ? (
        <SkeletonContainer />
      ) : (
        <>
          <HStack mb={6} gap={2}>
            <Heading color="gray.900" fontSize="3xl">
              {data.customer.name}
            </Heading>
            <Button variant="link" fontSize="sm" onClick={onClick}>
              Change
            </Button>
            <Spacer />
            <Tooltip
              label={<TooltipLabel />}
              placement="auto"
              openDelay={100}
              bgColor="white"
              shadow="lg"
            >
              <InfoIcon color="blue.600" />
            </Tooltip>
            <ResetAllButton display={['none', 'flex']} />
          </HStack>
          {data.customer.displays.length === 0 ? (
            <Heading color="gray.900" fontSize="2xl">
              No displays found
            </Heading>
          ) : (
            <DisplayContainer displays={data.customer.displays} />
          )}
        </>
      )}
    </>
  );
};

export default Displays;
