import { Box, Flex, Grid, Skeleton, SkeletonText, Spinner } from '@chakra-ui/react';

function SkeletonContainer() {
  return (
    <>
      <Flex dir="row" justifyContent="space-between" mb={6}>
        <SkeletonText fontSize="3xl" noOfLines={1} w="25%" mb={6} />
        <Box mr={6}>
          <Spinner size="lg" />
        </Box>
      </Flex>
      <Grid
        templateColumns={{ base: 'none', sm: 'repeat(auto-fit, minmax(30%, 1fr))' }}
        rowGap={6}
        columnGap={10}
      >
        {Array.from({ length: 12 }).map((_, i) => (
          <Skeleton key={i} w="full" h={52} />
        ))}
      </Grid>
    </>
  );
}

export default SkeletonContainer;
