import { Flex } from '@chakra-ui/react';
import { Header } from 'components/organisms';
import Footer, { FOOTER_HEIGHT } from 'components/organisms/ui/Footer';
import { swrConfig } from 'core/config/swr';
import { ResetProvider } from 'core/contexts';
import { Outlet } from 'react-router-dom';
import { SWRConfig } from 'swr';

function ProtectedLayout() {
  return (
    <SWRConfig value={swrConfig}>
      <ResetProvider>
        <Header />
        <Flex
          as="main"
          flexDirection="column"
          px={{ base: 4, sm: 2, md: 0 }}
          py={{ base: 6 }}
          w={{ base: 'full', md: '85%' }}
          h="full"
          mx="auto"
          mb={FOOTER_HEIGHT}
        >
          <Outlet />
        </Flex>
        <Footer />
      </ResetProvider>
    </SWRConfig>
  );
}

export default ProtectedLayout;
