import { DEFAULTS } from 'core/config/displayDefaults';
import { gql } from 'graphql-request';

// Reset single display
export const resetDisplayQuery = gql`
  mutation ResetDisplay($id: ID!, $powerScheduleId: ID!, $alias: ID, $orientation: Orientation!) {
    displayUpdatePower(input: { id: $id, power: ${DEFAULTS.power} }) {
      display {
        power {
          reported
          desired
        }
      }
    }
    displayBulkUpdatePowerSchedule(
      input: { displayIds: [$id], powerScheduleId: $powerScheduleId }
    ) {
      displays {
        powerSchedule {
          schedule {
            title
          }
        }
      }
    }
    displayUpdateOrientation(
      input: { id: $id, orientation: $orientation }
    ) {
      display {
        orientation {
          reported
          desired
        }
      }
    }
    displayBulkUpdateVolume(input: { displayIds: [$id], mute: ${DEFAULTS.volume.mute}, level: ${DEFAULTS.volume.level} }) {
      displays {
        volume {
          isMuted {
            reported
            desired
          }
          level {
            reported
            desired
          }
        }
      }
    }
    displayUpdateAlias(input: {displayId: $id, alias: $alias}) {
      id
      alias
    }
    displayBulkUpdateBrightness(input: { displayIds: [$id], brightness: ${DEFAULTS.brightness} }) {
      displays {
        brightness {
          reported
          desired
        }
      }
    }
  }
`;

export const updateSourceQuery = gql`
  mutation updateSource($id: ID!, $source: String!) {
    displayUpdateInputSource(input: { id: $id, source: $source }) {
      display {
        inputSource {
          current {
            reported {
              playlistId
              bookmarkIndex
            }
            desired {
              playlistId
              bookmarkIndex
            }
          }
        }
      }
    }
  }
`;

export const addGroupQuery = gql`
  mutation AddGroup($id: ID!, $groupId: ID!) {
    displayAddGroup(input: { displayId: $id, groupId: $groupId }) {
      id
    }
  }
`;

export const resetPlaylistQuery = gql`
  mutation ResetPlaylist($id: String!, $playlistId: String!) {
    displayUpdatePlaylist(input: { displayId: $id, playlistId: $playlistId }) {
      display {
        id
      }
    }
  }
`;

export const applyRecommendedSettingsQuery = gql`
  mutation ApplyRecommendedSettings($id: ID!) {
    displayBulkApplyRecommendedSettings(input: { displayIds: [$id] }) {
      displays {
        id
      }
    }
  }
`;

export default resetDisplayQuery;
