import { Box, Flex, FormControl, FormLabel, Heading, Image, Select, Stack } from '@chakra-ui/react';
import { MainButton } from 'components/atoms';
import { useCustomer } from 'core/contexts/CustomerProvider';
import { Customer } from 'core/types/customer';
import { useNavigate } from 'react-router-dom';

const CUSTOMER = 'customer';

function CustomersForm() {
  const { allCustomers: customers, setCustomer, customer } = useCustomer();
  const navigate = useNavigate();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const customer = JSON.parse(e.currentTarget[CUSTOMER].value) as Customer;
    setCustomer(customer);
    navigate('/displays');
  };

  return (
    <Box
      bgColor="white"
      boxShadow="login"
      borderRadius="base"
      px={{ base: 4, md: 10 }}
      py={{ base: 8, md: 10 }}
      w="full"
      maxW="lg"
    >
      <Stack dir="column" spacing={14} w="full">
        <Stack direction="column" spacing={{ base: 4, md: 6 }}>
          <Flex position="relative" justifyContent="center">
            <Image
              src="/assets/loginLogo.svg"
              alt="Wave PPDS"
              objectFit="contain"
              w="full"
              h={40}
            />
          </Flex>
          <Heading color="gray.900" fontSize="2xl" textAlign="center">
            ISE Display Controller
          </Heading>
        </Stack>
        <form onSubmit={onSubmit}>
          <Stack direction="column" spacing={7}>
            <FormControl>
              <FormLabel htmlFor={CUSTOMER} color="blue.800" fontSize="md" fontWeight="semibold">
                Choose customer
              </FormLabel>
              <Select id={CUSTOMER} defaultValue={JSON.stringify(customer)}>
                {customers.map((customer) => (
                  <option key={customer.id} value={JSON.stringify(customer)}>
                    {customer.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <MainButton value="Confirm" type="submit" />
          </Stack>
        </form>
      </Stack>
    </Box>
  );
}

export default CustomersForm;
