import { userCookie } from 'core/config/userCookie';
import { GraphQLClient } from 'graphql-request';
import Cookies from 'js-cookie';

const endpoint = process.env.REACT_APP_API_TARGET as string;

export const client = new GraphQLClient(endpoint, {
  requestMiddleware: (request) => {
    const cookie = Cookies.get(userCookie.name);
    if (cookie) {
      return {
        ...request,
        headers: { ...request.headers, authorization: `Basic ${cookie}` },
      };
    } else {
      return request;
    }
  },
});
