import { login } from 'core/api';
import { userCookie } from 'core/config/userCookie';
import Cookies from 'js-cookie';
import { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthContext {
  user: string | undefined;
  authenticate: (apiKey: string) => Promise<void>;
  deauthenticate: () => void;
}

const initialState: AuthContext = {
  user: undefined,
  authenticate: () => Promise.resolve(),
  deauthenticate: () => undefined,
};

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContext>(initialState);

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const user = Cookies.get(userCookie.name);
  const navigate = useNavigate();

  const authenticate = async (apiKey: string) => {
    try {
      const data = await login(apiKey);
      Cookies.set(userCookie.name, data.apiKey, userCookie.options);
      navigate('/customers');
    } catch (e) {
      throw 'Your API key is invalid, please try again.';
    }
  };

  const deauthenticate = () => {
    Cookies.remove(userCookie.name);
    navigate('/');
    navigate(0); // Force refresh to refresh SWR cache.
  };

  const value = {
    user,
    authenticate,
    deauthenticate,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
