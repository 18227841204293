import { CustomersForm } from 'components/organisms';

function Customers() {
  return (
    <>
      <CustomersForm />
    </>
  );
}

export default Customers;
