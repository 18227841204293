import { BoxProps, chakra, Flex, Image, Spacer } from '@chakra-ui/react';
import { CancelButton } from 'components/atoms';
import { useAuth } from 'core/contexts/AuthProvider';
import { OmitStrict } from 'core/types/chakra';
import { Link } from 'react-router-dom';

type Props = OmitStrict<BoxProps, 'zIndex' | 'position' | 'top' | 'left' | 'right'>;

export default function Header({ ...rest }: Props) {
  const { deauthenticate } = useAuth();

  return (
    <>
      <chakra.header
        zIndex="sticky"
        position="sticky"
        top={0}
        left={0}
        right="unset"
        width="full"
        background="white"
        shadow="header"
        {...rest}
      >
        <Flex
          as="nav"
          dir="row"
          align="center"
          w={{ base: 'full', md: '85%' }}
          h="full"
          px={{ base: 4, sm: 2, md: 0 }}
          py="0.625rem"
          mx="auto"
        >
          <Link to="/">
            <Flex position="relative">
              <Image
                src="/assets/headerLogo.svg"
                alt={'TPV Reset'}
                objectFit="contain"
                width={134}
              />
            </Flex>
          </Link>
          <Spacer />
          <CancelButton value="Log out" onClick={deauthenticate} />
        </Flex>
      </chakra.header>
    </>
  );
}
