import { AllCustomers } from 'core/types/customer';
import { getAllCustomersQuery } from 'gql';
import logger from 'utils/logger';
import { client } from '../client';

const getAllCustomers = async () => {
  try {
    const data = await client.request<AllCustomers>(getAllCustomersQuery);
    return data.organization.customers;
  } catch (e) {
    logger.error(JSON.stringify(e, undefined, 2));
    throw new Error('Fetching all customers has failed.');
  }
};

export default getAllCustomers;
