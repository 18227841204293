import { gql } from 'graphql-request';

export const getDisplaysQuery = gql`
  query GetDisplays($id: ID!) {
    customer(id: $id) {
      displays {
        id
        alias
        commercialTypeNumber
        serialNumber
        power {
          reported
        }
        powerSchedule {
          schedule {
            title
          }
        }
        inputSource {
          current {
            reported {
              source
            }
          }
        }
        orientation {
          reported
        }
        volume {
          isMuted {
            reported
          }
          level {
            reported
          }
        }
        brightness {
          reported
        }
        recommendedSettings {
          reported {
            recommended
          }
        }
        groups {
          id
          name
        }
        playlist {
          current {
            title
          }
          sync {
            id
            ... on PlaylistSyncing {
              progress
            }
          }
        }
      }
    }
  }
`;

export default getDisplaysQuery;
