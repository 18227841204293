import { chakra, Stack } from '@chakra-ui/react';
import { ResetAllButton } from 'components/molecules';

export const FOOTER_HEIGHT = 28;
const FOOTER_PX = 4;
const FOOTER_PY = 5;

function Footer() {
  return (
    <chakra.footer
      pos="fixed"
      bottom={0}
      insetX={0}
      height={28}
      bgColor="#F6FBFF"
      shadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
      borderTopLeftRadius={8}
      borderTopRightRadius={8}
      px={FOOTER_PX}
      py={FOOTER_PY}
      border="1px solid"
      borderColor="white"
      display={{ base: 'flex', sm: 'none' }}
    >
      <Stack align="center" h="full" w="full">
        <ResetAllButton w="full" size="lg" />
      </Stack>
    </chakra.footer>
  );
}

export default Footer;
