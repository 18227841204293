import { getAllCustomers } from 'core/api';
import { Customer } from 'core/types/customer';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthProvider';

interface CustomerContext {
  customer: Customer | null;
  setCustomer: (customer: Customer | null) => void;
  allCustomers: Customer[];
}

const initialState: CustomerContext = {
  customer: null,
  setCustomer: () => undefined,
  allCustomers: [],
};

interface CustomerProviderProps {
  children: React.ReactNode;
}

const CustomerContext = createContext<CustomerContext>(initialState);

export const useCustomer = () => useContext(CustomerContext);

const CustomerProvider = ({ children }: CustomerProviderProps) => {
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [allCustomers, setAllCustomers] = useState<Customer[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    const getData = async () => {
      const data = await getAllCustomers();
      setAllCustomers(data);
    };

    if (user) {
      getData();
    } else {
      setAllCustomers([]);
    }
  }, [user]);

  const value = {
    customer,
    setCustomer,
    allCustomers,
  };

  return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>;
};

export default CustomerProvider;
