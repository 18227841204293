import { Box, IconProps } from '@chakra-ui/react';
import Loading from 'react-loading';

function LoadingIcon({ height = '4', width = '4', color = 'blue.500' }: IconProps) {
  return (
    <Box height={height} width={width} color={color}>
      <Loading height="100%" width="100%" color={'currentColor'} type="bars" />
    </Box>
  );
}

export default LoadingIcon;
