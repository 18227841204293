/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
type LogType = {
  info: (message: string) => void;
  warn: (message: string) => void;
  trace: (message: string) => void;
  debug: (message: string) => void;
  error: (message: string) => void;
};

const logger = (() => {
  const logsEnabled = process.env.REACT_APP_LOGGER_ENABLED === 'true' || false;
  const isDev = process.env.NODE_ENV !== 'production';

  const print = (type: keyof LogType, ...messages: any[]) => {
    if (logsEnabled || isDev) {
      switch (type) {
        case 'info':
          console.info('[INFO] Log:', ...messages);
          break;
        case 'warn':
          console.warn('[WARN] Log:', ...messages);
          break;
        case 'error':
          console.error('[ERROR] Log:', ...messages);
          break;
        case 'trace':
          console.trace('[TRACE] Log:', ...messages);
          break;
        case 'debug':
        default:
          console.log('[DEBUG] Log:', ...messages);
      }
    }
  };

  return {
    debug: print.bind(null, 'debug'),
    info: print.bind(null, 'info'),
    warn: print.bind(null, 'warn'),
    error: print.bind(null, 'error'),
    trace: print.bind(null, 'trace'),
  };
})();

export default logger;
