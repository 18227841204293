import { List, ListItem, Text, VStack } from '@chakra-ui/react';
import { DEFAULTS } from 'core/config/displayDefaults';

const VALUES = [
  { label: 'Power', value: DEFAULTS.power },
  { label: 'Power schedule', value: DEFAULTS.powerScheduleTitle },
  { label: 'Playlist', value: DEFAULTS.playlistTitle },
  { label: 'Source', value: DEFAULTS.source },
  { label: 'Orientation', value: DEFAULTS.orientation },
  { label: 'Volume mute', value: DEFAULTS.volume.mute ? 'Yes' : 'No' },
  { label: 'Volume level', value: DEFAULTS.volume.level },
  { label: 'Group', value: DEFAULTS.groupTitle },
  { label: 'Brightness', value: DEFAULTS.brightness },
  { label: 'Recommended', value: DEFAULTS.recommended ? 'Yes' : 'No' },
];

const TooltipLabel = () => (
  <VStack gap={2} p={1} color="black">
    <Text alignSelf="start">Global default settings:</Text>
    <List>
      {VALUES.map(({ label, value }) => (
        <ListItem key={label}>
          {label}: {value}
        </ListItem>
      ))}
    </List>
  </VStack>
);

export default TooltipLabel;
